.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.contact-container h1 {
    font-size: clamp(2rem, 5vw, 2.5rem);
    margin-bottom: 2rem;
    color: var(--primary-text);
}

.contact-content {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 3rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.contact-subtitle {
    color: var(--secondary-text);
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.form-group label {
    font-size: clamp(1rem, 3vw, 1.2rem);
    color: var(--primary-text);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
    min-height: 150px;
    max-width: 100%;
}

button[type="submit"] {
    align-self: flex-start;
    padding: 0.75rem 2rem;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    background: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

button[type="submit"]:hover {
    background: #ff6666;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .contact-container {
        padding: 1rem;
        margin-top: 1rem;
    }
    
    .form-group {
        gap: 0.25rem;
    }
    
    .form-group input,
    .form-group textarea {
        padding: 0.5rem;
    }
    
    button[type="submit"] {
        width: 100%;
        padding: 0.75rem 1rem;
    }
}

/* Handle smaller screens */
@media (max-width: 480px) {
    .contact-container {
        padding: 1rem 0.5rem;
    }
    
    .form-group textarea {
        min-height: 120px;
    }
}

/* Prevent iOS zoom on input focus */
@media screen and (max-width: 768px) {
    input, textarea, select {
        font-size: 16px !important;
    }
}

/* Handle landscape orientation */
@media screen and (max-height: 480px) and (orientation: landscape) {
    .contact-container {
        padding: 1rem;
    }
    
    .form-group textarea {
        min-height: 80px;
    }
}

.alert {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
}

.alert-error {
    background-color: #f8d7da;
    color: #721c24;
}

.alert-info {
    background-color: #cce5ff;
    color: #004085;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
}

.btn:hover {
    background-color: #0056b3;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    text-align: center;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    text-align: center;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}