.navigation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  gap: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  font-size: 1.1rem;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.logo-section {
  text-decoration: none;
  color: #000000;
  font-weight: 800;
  letter-spacing: -0.02em;
  font-size: 1.3rem;
}

/* Dark mode handling */
[data-theme='dark'] .logo-section {
  color: #ffffff;
}

.route-section {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95rem; /* Slightly smaller for routes */
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.route-section::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.separator {
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 500;
  padding: 0 2px;
}

.route-segment {
  color: var(--text-color);
  text-decoration: none;
  white-space: nowrap;
  transition: opacity 0.2s ease;
}

.route-segment:hover {
  opacity: 0.7;
}

.next-route {
  color: #cc0000;
  text-decoration: none;
  white-space: nowrap;
  transition: opacity 0.2s ease;
}

.next-route:hover {
  opacity: 0.7;
}

.cycle-button {
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.cycle-button .symbol {
  font-family: monospace;
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 500;
  position: relative;
  top: -1px;
}

@media (max-width: 768px) {
  .navigation-container {
    font-size: 1rem;
    padding: 15px;
    gap: 8px;
  }
  
  .route-section {
    font-size: 0.9rem;
    gap: 6px;
  }
  
  .cycle-button .symbol {
    font-size: 1.2rem;
  }
}

.date-stamp {
  animation: none;
  text-shadow: none;
}

.date-stamp:hover {
  text-shadow: none;
} 