.chat-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.chat-message {
  padding: 1.5rem 0;
  line-height: 1.6;
  border-bottom: 1px solid var(--secondary-color);
}

.chat-message:last-child {
  border-bottom: none;
}

.chat-message.system {
  color: var(--text-color);
  opacity: 0.8;
  font-size: 0.9rem;
}

.chat-message.user {
  font-family: monospace;
  font-size: 0.95rem;
}

.chat-message.assistant {
  color: var(--text-color);
}

.chat-message ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.chat-message li {
  margin: 0.5rem 0;
}

.chat-message p {
  margin: 0.75rem 0;
}

/* Dark mode handled by existing theme variables */

/* Mobile responsiveness */
@media (max-width: 768px) {
  .chat-container {
    padding: 1rem;
  }
  
  .chat-message {
    padding: 1rem 0;
  }
}

.whoami-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0.5rem;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent page scrolling */
  padding-top: 70px; /* Add padding at the top to account for the fixed header */
}

.suggestion-buttons {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.suggestion-btn {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(10px);
  min-width: 80px;
  text-align: center;
}

.suggestion-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Ensure the intro section is fully visible */
.intro-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  margin-top: 0; /* Remove top margin since we added padding to container */
  margin-bottom: 0.75rem;
  flex-shrink: 0; /* Prevent shrinking */
  z-index: 10; /* Ensure it stays on top */
  background-color: #f8f8f8; /* Light background to make it stand out */
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.headshot-container {
  width: 60px; /* Slightly larger headshot */
  height: 60px;
  flex-shrink: 0;
}

.headshot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #000;
  filter: brightness(1.2);
  transition: filter 0.3s ease;
  position: relative;
  z-index: 1;
}

.headshot:hover {
  filter: brightness(1.3);
}

.speech-bubble {
  flex: 1;
  font-size: 0.9rem; /* Slightly larger font */
  line-height: 1.3;
}

.ai-highlight {
  color: #cc3333;
}

.anything {
  font-style: italic;
}

.anytime {
  font-weight: bold;
}

.command-buttons {
  display: flex;
  gap: 0.5rem;
  margin: 0 0 0.75rem 0; /* Add bottom margin */
  padding: 0 0.5rem;
  flex-wrap: wrap;
  flex-shrink: 0; /* Prevent shrinking */
  z-index: 10; /* Ensure it stays on top */
}

.command-btn {
  background: #cc3333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  text-transform: lowercase;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 90px;
  height: 32px;
  border-radius: 0;
}

.command-btn.aboutme { background: #cc3333; }
.command-btn.resume { background: #cc3333; }
.command-btn.warlok { background: #cc3333; }

.command-btn:hover {
  transform: translateY(-2px);
}

/* Animation for button disappearance */
@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.command-btn.hiding {
  animation: zoomOut 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.terminal-input-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.prompt {
  color: #cc3333;
  font-family: monospace;
}

.terminal-input {
  background: transparent;
  border: none;
  color: white;
  font-family: monospace;
  font-size: 1rem;
  flex: 1;
}

.terminal-input:focus {
  outline: none;
}

/* Blinking cursor effect */
.terminal-input::after {
  content: '▋';
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .whoami-container {
    padding: 0.25rem;
    padding-top: 60px; /* Smaller padding on mobile */
  }

  .intro-section {
    padding: 0.25rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .headshot-container {
    width: 50px;
    height: 50px;
  }

  .speech-bubble {
    font-size: 0.85rem;
  }

  .command-buttons {
    padding: 0 0.25rem;
    gap: 0.25rem;
    margin: 0 0 0.5rem 0;
  }

  .terminal-wrapper {
    max-height: calc(100vh - 180px); /* Smaller on mobile */
  }
  
  .terminal-wrapper.with-streams,
  .terminal-wrapper.with-socials {
    max-height: calc(100vh - 180px - 30vh);
  }
}

/* Small mobile adjustments */
@media (max-width: 480px) {
  .whoami-container {
    padding-top: 50px; /* Even smaller padding on small mobile */
  }
  
  .intro-section {
    padding: 0.2rem;
    margin-bottom: 0.4rem;
  }
  
  .headshot-container {
    width: 45px;
    height: 45px;
  }
  
  .speech-bubble {
    font-size: 0.8rem;
  }
  
  .command-buttons {
    padding: 0 0.15rem;
    gap: 0.15rem;
    margin: 0 0 0.4rem 0;
  }
  
  .terminal-wrapper {
    max-height: calc(100vh - 160px); /* Even smaller on small mobile */
  }
  
  .terminal-wrapper.with-streams,
  .terminal-wrapper.with-socials {
    max-height: calc(100vh - 160px - 30vh);
  }
}

/* Add styles for social embeds */
.social-icons {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.linkedin-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  margin-left: 2px;
  width: 12px;
  height: 12px;
}

.social-embeds {
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.twitter-embed,
.linkedin-embed {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.linkedin-embed {
  height: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.linkedin-embed iframe {
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
  z-index: 1;
}

.twitter-embed {
  margin-bottom: 1rem;
}

.linkedin-link {
  display: inline-block;
  color: #0077b5;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.linkedin-link:hover {
  color: #004471;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .social-embeds {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .linkedin-embed {
    height: 500px;
  }
}

.twitch-icon,
.youtube-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  width: 14px;
  height: 14px;
}

.twitch-icon {
  margin-right: 2px;
}

.youtube-icon {
  margin: 0 2px;
}

.command-btn.streams {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

.streams-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 854px;
  width: 100%;
  margin: 1rem auto;
  padding: 0 1rem;
  animation: fadeIn 0.3s ease-in;
}

.twitch-embed-container,
.youtube-embed-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .streams-container {
    padding: 0 0.5rem;
  }
  
  .twitch-embed-container iframe,
  .youtube-embed-container iframe {
    height: 240px;
  }
}

/* Ensure buttons stack properly on very small screens */
@media (max-width: 360px) {
  .command-buttons {
    flex-direction: column;
    align-items: stretch;
    gap: 0.35rem;
  }

  .command-btn {
    width: 100%;
    min-width: unset;
  }

  .suggestion-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .suggestion-btn {
    width: 100%;
    min-width: unset;
  }
}

/* Terminal wrapper to control height and scrolling */
.terminal-wrapper {
  flex: 1; /* Take remaining space */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* Hide overflow at container level */
  min-height: 0; /* Critical for flexbox scrolling */
  max-height: calc(100vh - 220px); /* Reduce height to ensure intro is visible */
  transition: height 0.3s ease; /* Smooth height transition */
}

/* Adjust terminal height when content is expanded */
.terminal-wrapper.with-streams,
.terminal-wrapper.with-socials {
  max-height: calc(100vh - 220px - 30vh); /* Adjust for header and expanded content */
}

/* Remove the extra space at the bottom */
.terminal-container > div {
  flex: 1;
  min-height: 0; /* Critical for flexbox scrolling */
}

/* Remove conflicting terminal-text class */
.terminal-text {
  height: auto !important;
  max-height: 100% !important;
}

/* Streams and socials containers */
.streams-container,
.social-embeds {
  max-height: 30vh; /* Limit height to prevent overflow */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 0; /* Prevent shrinking */
  margin-bottom: 0.5rem;
  border-top: 1px solid #eee;
  padding-top: 0.5rem;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: max-height 0.3s ease; /* Smooth height transition */
}
