.blog-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #333; /* Dark grey for better readability */
    font-family: Arial, sans-serif;
}

.blog-detail h1 {
    margin-bottom: 20px;
    color: #000; /* Black */
}

.blog-meta {
    font-style: italic;
    color: #666; /* Grey */
    margin-bottom: 20px;
}

.blog-meta .author {
    margin-right: 15px;
}

.blog-content {
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Styling for markdown content */
.blog-content h1, .blog-content h2, .blog-content h3, 
.blog-content h4, .blog-content h5, .blog-content h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #000; /* Black */
}

.blog-content p {
    margin-bottom: 15px;
}

.blog-content a {
    color: #FF0000; /* Red */
    text-decoration: none;
    border-bottom: 1px solid #FF0000;
}

.blog-content a:hover {
    color: #CC0000; /* Darker red on hover */
    border-bottom: 2px solid #CC0000;
}

.blog-content ul, .blog-content ol {
    margin-bottom: 15px;
    padding-left: 30px;
}

.blog-content li {
    margin-bottom: 5px;
}

.blog-content code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
}

.blog-content pre {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 4px;
    overflow-x: auto;
    margin-bottom: 15px;
}

.blog-tags {
    font-style: italic;
    color: #666; /* Grey */
    margin-top: 20px;
}

.loading, .error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
}

.error {
    color: #FF0000; /* Red */
}

.blog-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
}

@media (min-width: 768px) {
    .blog-content img {
        max-width: 80%;
    }
}

@media (min-width: 1024px) {
    .blog-content img {
        max-width: 70%;
    }
}