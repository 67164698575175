.projects.container {
    padding-top: 60px;
    max-width: 900px;
    margin: 0 auto;
}

.project.section {
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 8px;
    background: var(--section-background);
    border: 1px solid var(--secondary-color);
    color: var(--section-text);
}

.project h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.project-image-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    max-height: 200px;
    overflow: hidden;
}

.project-image {
    max-width: 300px;
    max-height: 200px;
    width: auto;
    height: auto;
    object-fit: contain;
    cursor: pointer;
}

.project-description {
    text-align: center;
    margin: 1.5rem 0;
}

.project-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.project-links .btn {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background: var(--primary-color);
    color: var(--text-on-primary);
    text-decoration: none;
    transition: opacity 0.2s;
}

.project-links .btn:hover {
    opacity: 0.9;
}

.project-media {
    margin-top: 20px;
}

.project-media img,
.project-media video {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

/* These styles should be consistent with your global styles */
.btn {
    display: inline-block;
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #2980b9;
}

.btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Add this to ensure dark mode compatibility */
.section {
    background-color: var(--section-background);
    color: var(--section-text);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
}

.project-links .btn {
    margin-right: 10px;
    margin-bottom: 10px;
}