.blog-post {
    margin-bottom: 30px;
}

.blog-post h2 {
    margin-bottom: 10px;
}

.blog-post .date {
    font-style: italic;
    color: #666;
    margin-bottom: 10px;
}

.blog-post p {
    margin-bottom: 15px;
}

.blog-post .btn {
    display: inline-block;
}

.blog-date-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.preview-image-container {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}